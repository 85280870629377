const LOGIN = '/user/login'
const SIGNUP = '/user/signup'
const GET_QUESTIONS = '/questions'
const SUBMIT_QUESTIONNAIRE = '/submission'
const VACCINATION_SUGGESTIONS = '/vaccinationSuggestions'
const HEALTH_TEST_SUGGESTIONS = '/healthTestSuggestions'

const BMI_REPORT = '/bmiReport'
const PROFILE = '/user/profile'
const HEALTH_MONITOR_ANAYLYSIS = '/healthMonitorAnalysis'
const FAMILY_HISTORY_ANALYSIS = '/familyHistoryAnalysis';
const BOWL_MOMENT = '/bowelMovementReport'
const COVID_QUESTIONS = '/covidQuestions'
const SUMBIT_COVID_QUESTIONS = '/submitCovidAnswers'
const HERE_SECTION_DETAILS = '/heroSection'
const GET_ALL_SUBMISSIONS='/getAllSubmissions';

const G_AUTH_LOGIN = '/user/googleLogin'
const G_AUTH_SIGNUP = '/user/googleSignup'
/////////////////ADMIN//////////////////
const LOGIN_ADMIN = '/admin/login';
const GET_DISEASES='/admin/diseases';
const GET_DISEASES_COUNT='/admin/diseasescount';



export {
    LOGIN, SIGNUP, SUBMIT_QUESTIONNAIRE, GET_QUESTIONS, VACCINATION_SUGGESTIONS, HEALTH_TEST_SUGGESTIONS,
    BMI_REPORT, PROFILE, HEALTH_MONITOR_ANAYLYSIS, BOWL_MOMENT, HERE_SECTION_DETAILS, COVID_QUESTIONS, SUMBIT_COVID_QUESTIONS,
    G_AUTH_LOGIN, G_AUTH_SIGNUP, FAMILY_HISTORY_ANALYSIS, LOGIN_ADMIN,GET_DISEASES,GET_DISEASES_COUNT,GET_ALL_SUBMISSIONS
}