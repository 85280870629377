import axios from "axios";
import errorDecorator from "../utils/errorDecorator";

const request = (options, onSuccess, onError) => {
  const { headers, ...restOptions } = options;
  const windowurl = window.location.host;
  let BASE_URL = "http://localhost:4025/api";
  switch (windowurl) {
    case "smarthealthassessment.com":
      BASE_URL = "https://api.smarthealthassessment.com/api";
      break;
    case "www.smarthealthassessment.com":
      BASE_URL = "https://api.smarthealthassessment.com/api";
      break;
    case "planheal.iscriptsdemo.com":
      BASE_URL = "https://planheal-api.iscriptsdemo.com/api";
      break;
    default:
      BASE_URL = "http://localhost:4025/api";
      break;
  }
  // const BASE_URL =
  //   windowurl === 'planheal.iscriptsdemo.com'
  //     ? 'https://planheal-api.iscriptsdemo.com/api'
  //     : 'http://localhost:4025/api';
  const client = axios.create({
    // baseURL: 'https://sha.planheal.com/api',
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      accept: "*/*",
      ...headers,
    },
  });
  const requestPromise = client(restOptions);

  if (onSuccess) {
    /*
      returns promise itself instead of resolving here
      if onsuccess is not passed as argument.
    */
    return requestPromise.then(onSuccess).catch((error, ...rest) => {
      onError(errorDecorator(error), ...rest);
      // onError(error)
    });
  }
  return requestPromise;
};

export default request;
