import { useEffect, useState } from 'react'
import { Row , Col, Input} from 'antd'
import { Typography, Button , Divider, Image} from 'antd';
import Login  from '../molecules/Login'
import Register  from '../molecules/Register'
import { Anchor } from 'antd';
import facebook from '../../assets/images/facebook.svg'
import twitter from '../../assets/images/twitter.svg'
import google from '../../assets/images/google.svg'
import healthTipImage from '../../assets/images/health-tip-img.png'
import Text from '../atoms/Text'
import { useHistory } from 'react-router';
import { GoogleLogin } from 'react-google-login';
import {queryParams} from '../../routes/private'
import { useDispatch, useSelector } from 'react-redux';
import{setrequestToken} from '../../reducers/paths'
const { Link } = Anchor;




const LoginOrRegister = ({onLogin, onSignup, onGauthSuccess, onGauthFailure}) => {
  const [currentPage, setCurrentPage] = useState({login: true, register: false})
  const { push } = useHistory()
  const dispatch = useDispatch()

  const authTypeIcons = [
    {icon: google, callToAction: null},
    // {icon: facebook, callToAction: null},
    // {icon: twitter, callToAction: null},
  ]


  useEffect(() => {
    if(window.location.pathname == '/signup'){
      setCurrentPage({...currentPage, login: false,  register: true})
    }
    const {qtoken} =queryParams(window.location.search);
    if(qtoken){
      dispatch(setrequestToken({data: qtoken}))
    }
  }, [])

  return (
    <Row>
      <Col span={14} offset={4} style={{alignContent:'center'}}>
        {currentPage.login && <Login onLogin={onLogin} />}
        {currentPage.register && <Register onSignup={onSignup} />}
        <div style={{marginTop: '27px', textAlign:'center'}}>
            <Divider> <Text lineHeight='24px' fontSize='md' color={'#808080'}>{`or ${currentPage.login ? 'Login' : 'Sign up'} with`}</Text></Divider>
            <div style={{marginTop: '18px',display:'flex', justifyContent: 'center'}}>
              {authTypeIcons.map((auth, index) => {
                  return (
                    <div key={index} style={{width: '48px', padding: '12px', height: '48px', borderRadius: '48px', border: '1px solid #E3E3E3', marginRight: '16px', display:'flex', justifyContent: 'center', alignItems: 'center',cursor:'pointer'}}>
                      {index == 0 &&  <GoogleLogin
                        clientId="1076700426351-5upv6pm5sbp6dsmc712rvcp1p5kg3505.apps.googleusercontent.com"
                        // buttonText="Login"
                        render={renderProps => (
                          <Image src={auth.icon} preview={false} onClick={renderProps.onClick} width={'24px'} height='24px' />
                        )}
                        onSuccess={data => onGauthSuccess(data, currentPage.login ? 'LOGIN' : 'SIGNUP')}
                        onFailure={onGauthFailure}
                        cookiePolicy={'single_host_origin'}
                      />}
                      {index !=0  &&  <Image src={auth.icon} preview={false} onClick={auth.callToAction} width={'24px'} height='24px' />}
                    </div>
                  )
              })}
            </div>
            <div style={{marginTop: '19px'}}>
              {currentPage.login && <>
                <Text lineHeight='22px' fontSize='sm' color={'#262626'}>Don't have an account?</Text>
                <Text ineHeight='22px' fontSize='sm' pointer color={'#F30618'} onClick={() => setCurrentPage({login: false, register: true})}> Sign up</Text>
              </>}
              {currentPage.register && <>
                <Text lineHeight='22px' fontSize='sm' color={'#262626'}>Have an account?</Text>
                <Text lineHeight='22px' fontSize='sm' pointer color={'#F30618'} onClick={() => setCurrentPage({login: true, register: false})}> Login</Text>
              </>}
            </div>
        </div>
      </Col>
    </Row>
  )
}

export default LoginOrRegister;