import { Row , Col, Input, Alert, Checkbox} from 'antd'
import { useState } from 'react';
import { Typography, Button , Divider,Form} from 'antd';
import Text from '../atoms/Text'
import { useSelector, useDispatch } from 'react-redux';
import { authSelector, setTermsAccepted } from '../../reducers/authentication'




const Register = ({onSignup}) => {

  const [state, setState] = useState({email: '', password: '',confirmpassword:''});
  const [erros, seterros] = useState({email: null, password: null,confirmpassword:null});
  const auth = useSelector(authSelector)
  const dispatch = useDispatch()


  const acceptTerms = () => dispatch(setTermsAccepted())

  function validate(){
    const isvalidEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.email.trim());
    const isvalidPassword = state.password!="";
    const isConfirmed =state.confirmpassword===state.password;
    return isvalidEmail&&isvalidPassword&&isConfirmed&&auth.termsAccepted;
  }
  function signUp(){
    onSignup(state.email.trim().toLowerCase(), state.password)
  }

  const validateMessages = {
    required: 'feild is required!',
    types: {
      email: "it is not a valid email!",
      //number: "${label} is not a valid number!",
      password:"Please Input your password!"
    }
  }
  // removing errors after certain time

  return (
    <>
        <div style={{display: 'flex', justifyContent:'center'}}>
          <Text bold fontSize='md'  lineHeight='32px' fontSize='xl' color={'#262626'}>Sign up</Text>
        </div>
        <Form
          name="siginup-form"
          onFinish={signUp}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={["user", "email"]}
            rules={[
              {
                type: "email",
                required: true,
              },
            ]}
          >
        <div style={{marginTop: '18px'}}>
          <Text lineHeight='22px' fontSize='sm' color={'#262626'}>Email</Text>
        <Input style={{marginTop: '8px'}} value={state.email} onChange={(e) => setState({...state, email: e.currentTarget.value})} size="large" placeholder="Enter your email address"  />
        </div>
        </Form.Item>
        {/* <div style={{marginTop: '24px'}}> */}
        <Form.Item
            name= {["password"]}
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <div>
          <Text lineHeight='22px' fontSize='sm' color={'#262626'}>Password</Text>
          <Input.Password style={{marginTop: '8px'}} value={state.password}  onChange={(e) => setState({...state, password: e.currentTarget.value})} placeholder='Enter password' size="large" />
          </div>
        </Form.Item>
        {/* </div> */}
        {/* <div style={{marginTop: '24px', marginBottom:'16px'}}> */}
        <Form.Item
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <div>
          <Text lineHeight='22px' fontSize='sm' color={'#262626'}>Confirm Password</Text>
          <Input.Password style={{marginTop: '8px'}} value={state.confirmpassword} onChange={(e) => setState({...state, confirmpassword: e.currentTarget.value})}placeholder={'Re-enter password'} size="large" />
          </div>
        </Form.Item>
        {/* </div> */}
        <div>
          <Checkbox checked={auth.termsAccepted} onChange={acceptTerms} />
          <Text lineHeight='24px' fontSize='sm' ml={'8px'} color={'rgba(0, 0, 0, 0.85)'}>{"I agree to Plan Heal's"}</Text>
          <Text lineHeight='24px' fontSize='sm' onClick={() => window.open(`${window.location.origin}/terms-of-service`, '_blank')} pointer bold ml={'4px'} mr={'4px'} color={'rgba(0, 0, 0, 0.45)'}>{'Terms of Service'}</Text>
          <Text lineHeight='24px' fontSize='sm' color={'rgba(0, 0, 0, 0.85)'}>{'and'}</Text>
          <Text lineHeight='24px' fontSize='sm' onClick={() => window.open(`${window.location.origin}/privacy-policy`, '_blank')} pointer bold ml={'4px'} color={'rgba(0, 0, 0, 0.45)'}>{'Privacy Policy'}</Text>
        </div>
        {auth.errors && <div style={{marginTop: '16px', textAlign: 'center'}}>
            <Alert message={auth.errors} type="error" />
          </div>
        }
        <div style={{marginTop: '24px'}}>
          <Button disabled={!auth.termsAccepted}  htmlType="submit"  loading={auth.loading} type="primary" size='large' style={{backgroundColor: '#F30618', borderColor: 'inherit', borderRadius: '4px', height: '48px'}} block>
            <Text lineHeight='24px' fontSize='md' bold color={'white'}>Sign up</Text> 
          </Button>
        </div>
        </Form>
    </>
  )
}


export default Register