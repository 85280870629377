import {Row, Col} from 'antd'
import { Image, Switch, Card, Avatar, Button } from 'antd';
import wellcomeImg from '../assets/images/wellcome-img.png'
import morningimg from '../assets/images/morning_img.png'
import Text from '../components/atoms/Text'
import { useHistory } from 'react-router-dom'
import {useEffect,useState} from 'react'
import moment from 'moment';
// import dashboardImage from '../assets/images/ProfilePage_HeroImage.jpg'
const { Meta } = Card;

const Dashboard = () => {

  const { push } = useHistory();
  const [greet, setgreet] = useState('');

  function generateGreetings(){
    var currentHour = moment().format("HH");
    if (currentHour >= 0 && currentHour < 12){
        setgreet("Good morning!");
    } else if (currentHour >= 12 && currentHour < 17){
      setgreet("Good afternoon!");
    }   else if (currentHour >= 17 && currentHour < 24){
      setgreet("Good evening!");
    } 
  }
  useEffect(() => {
    generateGreetings();
  }, []);

  return (
    <Row flex style={{justifyContent:'space-between', width:'100%', maxWidth: '1600px', marginTop: '60px'}}>
      <Col span={24} style={{display: 'flex', justifyContent:'center', alignItems:'center'}}>
        <Col span={10}>
        {/* <Text lineHeight='95px' fontSize='85px'   color={'#cc0000'}>TRACK <br/>THE <br/>FACTS,</Text>
        <Text lineHeight='50px'  display='block' padding-top='10px'  fontSize='40px' color={'#cc0000'}>WE’LL MAKE <br/>SENSE OF THEM. </Text>
         */}
         <Image preview={false} src={morningimg} style={{width:'100%'}} />
        </Col>
        <Col span={10} style={{padding: '24px', marginTop: '0px', display: 'flex', flexDirection: 'column',  alignContent: 'center',  paddingTop: '21px '}}>
          <Text lineHeight='38px' fontSize='xxxxxl' bold color={'#262626'}>{greet}</Text>
          <Text lineHeight='28px' fontSize='md'  color={'#262626'}>
            Let's get started on navigating a patient journey where you are more informed about your health, more confident to communicate what's happening with your health, and more aware of opportunities for you to access quality health information.</Text>
          <Button type="primary" onClick={() => push('/questions') } style={{backgroundColor: '#F30618', borderColor: 'inherit', height: '40px', marginTop: '40px', width: 'max-content'}} block>
            <Text lineHeight='24px' fontSize='md' bold color={'white'}>Start My Assessment</Text>
          </Button>
        </Col>
      </Col>

    </Row>
  )
}

export default Dashboard