import { Row , Col, Input, Alert, Checkbox} from 'antd'
import { useState } from 'react'
import { Typography, Button , Divider,Form} from 'antd';
import Text from '../atoms/Text'
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../reducers/authentication'


const AdminLogin = ({onLogin}) => {
  const [state, setState] = useState({email: '', password: ''})
  const auth = useSelector(authSelector)
  const dispatch = useDispatch()

  function LogIn(){
    console.log(state.email.trim().toLowerCase(), state.password);
    onLogin(state.email.trim().toLowerCase(),state.password)
  }
  const validateMessages = {
    required: 'Field is required!',
    types: {
      email: "it is not a valid email!",
      //number: "${label} is not a valid number!",
      password:"Please Input your password!"
    }
  }
  // removing errors after certain time
  const [form] =Form.useForm();
  return (
    <>
    
      <Col span={14} offset={4} style={{alignContent:'center'}}>
        <div style={{display: 'flex', justifyContent:'center'}}>
          <Text bold  lineHeight='32px' fontSize='xl' color={'#262626'}>Admin Login</Text>
        </div>
        <Form
          name="login-form"
          onFinish={LogIn}
          validateMessages={validateMessages} 
          form ={form}
        >
          <Form.Item
            name={["user", "email"]}
            rules={[
              {
                type: "email",
                required: true,
              },
            ]}
          >
        <div style={{marginTop: '18px'}}>
          <Text lineHeight='22px' fontSize='sm' color={'#262626'}>Email</Text>
          <Input style={{marginTop: '8px'}} value={state.email} onChange={(e) => setState({...state, email: e.currentTarget.value})} size="large" placeholder="Enter your email address"  />
        </div>
        </Form.Item>
        {/* <div style={{marginTop: '24px', marginBottom: '16px'}}> */}
        <Form.Item
            name= {["password"]}
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <div>
          <Text lineHeight='22px' fontSize='sm' color={'#262626'}>Password</Text>
          <Input.Password   value={state.password}  onChange={(e) => setState({...state, password: e.currentTarget.value})} style={{marginTop: '8px'}} placeholder='Enter password' size="large" />
        </div>
        </Form.Item>
        {/* </div> */}
         
        {auth.errors && <div style={{marginTop: '16px', textAlign: 'center'}}>
             <Alert message={auth.errors} type="error" />
          </div>
        }
        <div style={{marginTop: '16px'}}>
        <Form.Item shouldUpdate>
          <Button   loading={auth.loading}  htmlType="submit" type="primary" size='large' style={{backgroundColor: '#F30618', borderColor: 'inherit', borderRadius: '4px', height: '48px'}} block>
            <Text lineHeight='24px' fontSize='md' bold color={'white'}>Login</Text>
          </Button>
          </Form.Item>
          {/* <div style={{display: 'flex', justifyContent:'center'}}>
            <Text mt={'20px'} lineHeight='22px' fontSize='sm' color={'#F30618'}>Forgot Password</Text>
          </div> */}
        </div>
        </Form>
        </Col>
    </>
  )
}


export default AdminLogin;