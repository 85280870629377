
import { Typography } from 'antd'


const { Text: ExtendedText } = Typography;


const Text  = ({children, fontSize,  lineHeight, bold, color, letterSpacing, ...props}) =>{

  const textTheme = {
    fontSize: {sm: '14px', md: '16px', mdL: '18px', lg: '20px', xl: '22px', xxl: '24px', xxxl: '26px', xxxxl: '28px', xxxxxl: '30px'},
    // space: ['0', 1, 2, 4, 8, 16, 32, 64, 128, 256, 512], // in px
  }

  return (
    <ExtendedText onClick={props.onClick} style={{color, letterSpacing, fontSize: textTheme?.fontSize[fontSize] || fontSize, lineHeight, fontWeight: bold ? 'bold' : '400',
    marginTop: props.mt, marginLeft: props.ml, marginBottom: props.mb,marginRight: props.mr, cursor: props.pointer ? 'pointer': 'inherit', display: props.display || 'inline',...props.style}}>
      {children}
    </ExtendedText>
  )
}


Text.defaultProps = {
  fontSize: 'md',
  bold: false,
  lineHeight: '12px',
  color: 'black',
  letterSpacing: '0.5px',
  mt: 0,
  ml: 0,
  mr: 0,
  mb: 0,
  onClick: null
}


export default Text;