export default function authHeader() {
    const token = localStorage.getItem("token")
  
    if (token) {
      return { "token":  token };
    } else {
      return {};
    }
  }
  export function adminHeader() {
    const token = localStorage.getItem("admin_token")
  
    if (token) {
      return { "token":  token };
    } else {
      return {};
    }
  }