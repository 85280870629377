
import {Modal} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal


function showConfirm(onOk) {
  confirm({
    title: 'Are you sure ?',
    icon: <ExclamationCircleOutlined />,
    content: 'By closing, you will be discarding any answers that you may have already entered on this page. ',
    okText: 'Discard Answers',
    cancelText: 'Cancel',
    okType: 'danger',
    okButtonProps: {
      buttonType: 'primary',
    },
    onOk() {
      onOk()
    },
    onCancel() {
      //do nothing just close
    },
  });
}

export default showConfirm