import request from '../utils/request';
import {adminHeader}  from '../utils/authHeader';
import {GET_DISEASES,GET_DISEASES_COUNT} from '../routes/api'
export default class AdminApi {
    static getDiseasedata = (options={}, onSuccess, onError) => {
      options['headers']= adminHeader();
      return request({url: GET_DISEASES, ...options, method: 'get'}, onSuccess, onError)
    }
    static getDiseaseCount(options={},onSuccess, onError) {
      options['headers']= adminHeader();
      return request({url: GET_DISEASES_COUNT, ...options, method: 'get'}, onSuccess, onError)
    }
}