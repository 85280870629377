import request from '../utils/request'
import { LOGIN, SIGNUP,  BMI_REPORT, PROFILE, HEALTH_MONITOR_ANAYLYSIS,FAMILY_HISTORY_ANALYSIS, BOWL_MOMENT,  HERE_SECTION_DETAILS, COVID_QUESTIONS  } from '../routes/api'
import authHeader  from '../utils/authHeader'


export default class HomeApi {
  static bmiReportHistory = (options, onSuccess, onError) => {
    options['headers']= authHeader()
    return request({url: BMI_REPORT, ...options, method: 'get'}, onSuccess, onError)
  }

  static bowlMomementHistory = (options, onSuccess, onError) => {
    options['headers']= authHeader()
    return request({url: BOWL_MOMENT, ...options, method: 'get'}, onSuccess, onError)
  }

  static profileDetails = (options, onSuccess, onError) => {
    options['headers']= authHeader()
    return request({url: PROFILE, ...options, method: 'get'}, onSuccess, onError)
  }

  static HealthMonitorAnalysis = (options, onSuccess, onError) => {
    options['headers']= authHeader()
    return request({url: HEALTH_MONITOR_ANAYLYSIS, ...options, method: 'get'}, onSuccess, onError)
  }

  static FamilyHistoryAnalysis = (options, onSuccess, onError) => {
    options['headers']= authHeader()
    return request({url: FAMILY_HISTORY_ANALYSIS, ...options, method: 'get'}, onSuccess, onError)
  }

  static getProfileDetails = (options, onSuccess, onError) => {
    options['headers']= authHeader()
    return request({url: PROFILE, ...options, method: 'get'}, onSuccess, onError)
  }

  static updateProfileDetails = (options, onSuccess, onError) => {
    options['headers']= authHeader()
    return request({url: PROFILE, ...options, method: 'post'}, onSuccess, onError)
  }


  static hereSectionDetails = (options, onSuccess, onError) => {
    options['headers']= authHeader()
    return request({url: HERE_SECTION_DETAILS, ...options, method: 'get'}, onSuccess, onError)
  }

  static covidQuestions = (options, onSuccess, onError) => {
    options['headers']= authHeader()
    return request({url: COVID_QUESTIONS, ...options, method: 'get'}, onSuccess, onError)
  }

}