// // import { LOGIN } from '../routes/public';
// import toast from '../utils/toast';
// import { isInsuranceRole } from '../utils/common';
import { notification } from 'antd'

// TODO:: Add in custom messages across application to maintain consistancy around error messages.
const errorDecorator = (error) => {

  const openNotification = () => {
    const args = {
      message: 'Session exired',
      description:
        'Your session has expired. Please login again to continue.',
    };
    notification.open(args);
  };

  const LOGIN = '/login'
  if(error.response && error.response.status === 401) {
    if(window.history && window.history.state) {
      const paths = [LOGIN].map(route => route.path)
      if(paths.indexOf(window.location.pathname) === -1){
        openNotification()
      }
      // Check for history.state here: https://developer.mozilla.org/en-US/docs/Web/API/History/state
    }
    setTimeout(() => {
      if(window.location.pathname != LOGIN)
        window.location.pathname =  LOGIN
    }, 2000)
  }
  return error;
}

export default errorDecorator;