import { LOGOUT,LOGIN_FAILURE, LOGIN_SUCCESS,REGISTER_SUCCESS, REGISTER_FAIL,LOADING } from "../constants";
import { AuthApi  } from "../services";

const onSignup = (username, password) => (dispatch) => {

  return AuthApi.signup({data: {email: username, password}}, (response) => {
    localStorage.setItem('token', JSON.stringify(response.data.token))
    dispatch({type: REGISTER_SUCCESS, payload: { token: response.data.token} })
  },
  (error) => {
    dispatch({type: REGISTER_FAIL, payload: { registerError: error?.response?.data?.message}})
  }
  ).then(() => {
    Promise.resolve()
  })
}

const onLogin = (username, password) => (dispatch) => {
  return AuthApi.login({data: {email: username, password}}, (response) => {
      localStorage.setItem('token', JSON.stringify(response.data.token))
      dispatch({type: LOGIN_SUCCESS,  payload: { token: response.data.token}})
    },
    (error) => {
      dispatch({type: LOGIN_FAILURE,  payload: { loginError: error?.response?.data?.message}})
    }
    ).then(() => {
      Promise.resolve()
    })
}

const onLogout = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    localStorage.removeItem('token')
    dispatch({type: LOGOUT,  payload: { isAuthenticated: false}})
    resolve()
  })
}

export {onSignup, onLogin, onLogout}