import { useState, useEffect } from 'react'
import { Row, Col } from 'antd';
import { Layout, Image, Typography, Spin } from 'antd';
import LoginOrRegister from '../components/organisms/LoginOrRegister'
import Header from '../components/organisms/Header'
import HealthTipContainer from '../components/molecules/HealthTipContainer'
import { useHistory  } from 'react-router-dom'
import bgImg from '../assets/images/bg-img.png'
import { useSelector, useDispatch } from 'react-redux'
import { onSignup as Register, onLogin as Login } from '../actions/auth'
import { LOADING } from '../constants'
import { authSelector, loginAdmin, register, gAuthLogin, gAuthSignup, setSpiLoading  } from '../reducers/authentication'
import AdminLogin from '../components/molecules/AdminLogin'

const { Text } = Typography;

const AdminLoginAuth = () => {

  const authState = useSelector(authSelector)
  const {isAuthenticated , loading, errros, authToken, spinLoading } = authState
  const dispatch = useDispatch();
  const { push } = useHistory()


  const onLogin = async (username, password) => {
    dispatch(loginAdmin({username, password})).then((response) => {
      if(response?.payload?.token){
        localStorage.setItem('admin_token', JSON.stringify(response?.payload?.token))
        //if(response.payload.isNewUser)
        push('/admin/dashboard')
        ///else
       
      }else{
         push('/admin/login')
      }
    })
  }

  const container =  (
    <div width='100vw'>
      <Row>
        <HealthTipContainer />
        <Col span={10} style={{paddingTop: '8%', background: 'white'}}>
          <AdminLogin onLogin={onLogin}/>
          {/* <LoginOrRegister onSignup={onSignup} onLogin={onLogin} onGauthSuccess={onGauthSuccess} onGauthFailure={onGauthFailure}  /> */}
        </Col>
      </Row>
    </div>
  )

  
  return (
    <>
      <Spin spinning={spinLoading} delay={500}>
        {container}
      </Spin>
    </>
  )
}


export default AdminLoginAuth;