import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


const initialState = {
  errors: {exists: false, messages: []},
  multipleAnswer: {}, //for bp, height
  inputData: {},
  submissionPercentage: 0,
  questionsData: undefined,
  requiredLength: 0,
  headerData: {},
  currentPath: {path: 'default'},
  confirmCovidAnswers: false,
  requestToken:undefined
}


const pathSlice = createSlice({
  name: 'paths',
  initialState,
  reducers: {
    setInputData: (state=initialState, action) => {
      const newData = action.payload.data
      if(newData.value == ''){
        delete state.inputData[newData.sequence];
      }
      state.inputData = {...state.inputData, [newData.sequence]: {questionId: newData._id, answer: newData.value }}
      console.log(state.inputData)
      
    },
    setQuestionsData: (state, action) => {
      state.questionsData = action.payload.data
      state.requiredLength = action.payload.data.data.questions.filter((q) => q.required).length
    },
    setMultipleAnswerValue: (state, action) => {

    },
    setHeaderData: (state, action) => {
      state.headerData = action.payload.data
    },
    setCurrentPath: (state, action) => {
      state.currentPath = action.payload.data
    },
    setConfirmCovidAnswers: (state, action) => {
      state.confirmCovidAnswers = !state.confirmCovidAnswers
    },
    cleanUpInputData: (state, action) => {
      state.inputData = {}
    },
    setrequestToken:(state,action)=>{
      state.requestToken=action.payload.data;
    }
  }
})


export const { setInputData, setQuestionsData, setMultipleAnswerValue, cleanUpInputData, setHeaderData, setCurrentPath,
  setConfirmCovidAnswers,setrequestToken } = pathSlice.actions

export const pathsSelector = (state) => state.paths


const pathsReducer = pathSlice.reducer
export default pathsReducer


