import { useState, useEffect } from 'react'
import { Row, Col } from 'antd';
import { Layout, Image, Typography, Spin } from 'antd';
import LoginOrRegister from '../components/organisms/LoginOrRegister'
import Header from '../components/organisms/Header'
import HealthTipContainer from '../components/molecules/HealthTipContainer'
import { useHistory  } from 'react-router-dom'
import bgImg from '../assets/images/bg-img.png'
import { useSelector, useDispatch } from 'react-redux'
import { onSignup as Register, onLogin as Login } from '../actions/auth'
import { LOADING } from '../constants'
import { authSelector, login, register, gAuthLogin, gAuthSignup, setSpiLoading  } from '../reducers/authentication'


const { Text } = Typography;

const GetStarted = () => {

  const authState = useSelector(authSelector)
  const {isAuthenticated , loading, errros, authToken, spinLoading } = authState
  const dispatch = useDispatch();
  const { push } = useHistory()


  

  const onSignup = (username, password) => {
    dispatch(register({username, password})).then((response) => {
      if(response?.payload?.token){
        localStorage.setItem('token', JSON.stringify(response?.payload?.token))
        push('/dashboard')
      }
    })
  }

  const onLogin = async (username, password) => {
    dispatch(login({username, password})).then((response) => {
      if(response?.payload?.token){
        localStorage.setItem('token', JSON.stringify(response?.payload?.token))
        if(response.payload.isNewUser)
        push('/dashboard')
        else
        push('/')
      }
    })
  }

  const onGauthSuccess = (data, currentPage) => {
    if(currentPage == 'LOGIN'){
      dispatch(gAuthLogin({token: data.tokenId})).then((response) => {
        if(response?.payload?.token){
          localStorage.setItem('token', JSON.stringify(response?.payload?.token))
          if(response.payload.isNewUser)
          push('/dashboard')
          else
          push('/')
        }
      })
    }else{
      dispatch(gAuthSignup({token: data.tokenId})).then((response) => {
        if(response?.payload?.token){
          localStorage.setItem('token', JSON.stringify(response?.payload?.token))
          if(response.payload.isNewUser)
          push('/dashboard')
          else
          push('/')
        }
      })
    }
  }

  const onGauthFailure = response => {
    
  }


 
  const container =  (
    <div width='100vw'>
      <Row>
        <HealthTipContainer />
        <Col span={10} style={{paddingTop: '3%', background: 'white'}}>
          <LoginOrRegister onSignup={onSignup} onLogin={onLogin} onGauthSuccess={onGauthSuccess} onGauthFailure={onGauthFailure}  />
        </Col>
      </Row>
    </div>
  )

  
  return (
    <>
      <Spin spinning={spinLoading} delay={500}>
        {container}
      </Spin>
    </>
  )
}


export default GetStarted