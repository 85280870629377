import { Col, Image, Typography } from 'antd'
import healthTipImage from '../../assets/images/health-tip-img.png'
import bgImg from '../../assets/images/bg-img.png'
import personImg from '../../assets/images/person-login.png'
import Header from '../organisms/Header'
import Text from '../atoms/Text'
import groupBg from '../../assets/images/group-bg.png'
import groupFaces from '../../assets/images/group-faces.png'
import personsBg from '../../assets/images/login_bg3.jpg'
import person1 from '../../assets/images/person-1.png'
import person2 from '../../assets/images/person-2.png'
import person3 from '../../assets/images/person-3.png'
import person4 from '../../assets/images/person-4.png'
import person5 from '../../assets/images/person-5.png'
import person6 from '../../assets/images/person-6.png'
import bannerimage from '../../assets/images/Group-face2.png'


 

//note:  center image structure needs to be changed as per individual pictures

const HealthTipContainer = () => {
  return (
    <>
    <Col span={14} flex style={{height: '100vh',backgroundImage: `url(${personsBg})`, backgroundSize: 'cover', backgroundPosition: 'center', justifyContent:'center', display:'flex', flexDirection:'column'}} >
        <Header position={'relative'} zIndex={false} />         
        <div style={{display:'flex', flexDirection: 'column', justifyContent:'center', alignItems:'center', padding: '24px', flexGrow: 100, marginTop:'192px'}}>
          {/* <div style={{width:'460px', display:'flex', flexDirection:'column'}}>
            <div style={{float:'left'}}>
              <div style={{display:'flex', width:'230px', justifyContent:'space-around', backgroundColor:'white'}}>
                <Image
                  preview={false}
                  src={person1}
                  style={{width:'101px', height:'101px', borderRadius:'4px'}}
                  // style={{position: 'absolute', top: '25%', borderRadius: '10px', marginTop: '26px'}}
                />
                  <Image
                  preview={false}
                  src={person2}
                  style={{width:'101px', height:'101px', borderRadius:'4px'}}
                  // style={{position: 'absolute', top: '25%', borderRadius: '10px', marginTop: '26px'}}
                />
              </div>
              <div style={{display:'flex', justifyContent:'space-around',backgroundColor:'white', paddingTop: '16px' }}>
                <Image
                  preview={false}
                  src={person3}
                  style={{width:'101px', height:'101px', borderRadius:'4px'}}
                  // style={{position: 'absolute', top: '25%', borderRadius: '10px', marginTop: '26px'}}
                />
                  <Image
                  preview={false}
                  src={person4}
                  style={{width:'101px', height:'101px', borderRadius:'4px'}}
                  // style={{position: 'absolute', top: '25%', borderRadius: '10px', marginTop: '26px'}}
                />
                <Image
                  preview={false}
                  src={person5}
                  style={{width:'101px', height:'101px',borderRadius:'4px'}}
                  // style={{position: 'absolute', top: '25%', borderRadius: '10px', marginTop: '26px'}}
                />
                <Image
                  preview={false}
                  src={person6}
                  style={{width:'101px', height:'101px', borderRadius:'4px'}}
                  // style={{position: 'absolute', top: '25%', borderRadius: '10px', marginTop: '26px'}}
                />
              </div>
            </div>
            <div style={{display:'flex', flexDirection:'column', alignItems:'center', textAlign: 'center', backgroundColor:'white', paddingTop:'22px', paddingBottom:'24px'}}>
              <Text lineHeight='24px' fontSize='md' mb={'8px'} bold color={'#262626'}>Here’s a Health Tip for you</Text>
              <div >
                <Text lineHeight='24px' fontSize='md' mt={'4px'} color='#8c8c8c'>If you’re taking a medication for more than 6 months, ask your doctor if you should still be taking it.</Text>
              </div>
            </div>
          </div> */}
       <Image
                  preview={false}
               
                  
                  // style={{position: 'absolute', top: '25%', borderRadius: '10px', marginTop: '26px'}}
                />
        {/* <div style={{display:'flex', flexDirection:'column', alignItems:'center', textAlign: 'center', padding:'15px', radius:'4px', backgroundColor:'#ffffffc2', paddingTop:'22px', paddingBottom:'24px'}}>
              <Text lineHeight='24px' fontSize='md' mb={'8px'} bold color={'#262626'}>Here’s a Health Tip for you</Text>
              <div >
                <Text lineHeight='24px' fontSize='md' mt={'4px'} color='rgb(36 36 36)'>If you’re taking a medication for more than 6 months, ask your doctor if you should still be taking it.</Text>
              </div>
            </div> */}
      </div>
    </Col>
   </>
  )
}


export default HealthTipContainer