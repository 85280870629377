import request from '../utils/request'
import { LOGIN, SIGNUP, G_AUTH_LOGIN, G_AUTH_SIGNUP ,LOGIN_ADMIN } from '../routes/api'

export default class AuthApi {
  static login = (options, onSuccess, onError) => {
    return request({url: LOGIN, ...options, method: 'post'}, onSuccess, onError)
  }

  static signup = (options, onSuccess, onError) => {
    return request({url: SIGNUP, ...options, method: 'post'}, onSuccess, onError)
  }

  static GauthSignup = (options, onSuccess, onError) => {
    options['headers']= {token: options.token}
    return request({url: G_AUTH_SIGNUP, ...options, method: 'post'}, onSuccess, onError)
  }

  static GauthSignin = (options, onSuccess, onError) => {
    options['headers']= {token: options.token}
    return request({url: G_AUTH_LOGIN, ...options, method: 'post'}, onSuccess, onError)
  }
  static loginAdmin = (options, onSuccess, onError) => {
    return request({url: LOGIN_ADMIN, ...options, method: 'post'}, onSuccess, onError)
  }
}