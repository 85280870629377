import { useState, useEffect } from 'react'
import {Row, Image, Col, Typography, Dropdown, Menu, Button,Modal} from 'antd'
import logo from '../../assets/images/logo.svg'
import user from '../../assets/images/user.svg'
// import { CaretDownOutlined } from ''
import Text from '../atoms/Text'
import { CaretDownOutlined } from '@ant-design/icons';
import {useHistory} from 'react-router-dom'
import { isBinding } from '@babel/types'
import { onLogout } from '../../actions/auth'
import { useDispatch, useSelector } from 'react-redux'
import questions from '../../reducers/questions'
import classNames from 'classnames'
import '../organisms/styles.css'
import showConfirm from '../atoms/ConfirmModal'
import leftArrow from '../../assets/images/left-arrow.png'
import { cleanUpInputData, pathsSelector } from '../../reducers/paths'



const Header  = ({loggedIn , position, logoRequired = true, ...props}) => {

  const { push } = useHistory()
  const dispatch = useDispatch()
  const { headerData } = useSelector(pathsSelector)


  const pageState = {
    home: ['/dashboard', '/', '/profile'].includes(window.location.pathname),
    questions: window.location.pathname == '/questions'
  }

  const logout = () => {
    dispatch(onLogout()).then(() => {
      push('/login')
    })
  }

  const onOk = () => {
    dispatch(cleanUpInputData())
    push('/')
  }

  const menu = (
    <Menu style={{float: 'left', padding: '16px', minWidth: '150px'}}>
      <Menu.Item style={{marginBottom: '4px'}} onClick={() => push('/profile')}>
        <Text lineHeight='22px' mb={'4px'} pointer fontSize='sm' color={'#F30618'}>Profile</Text>
      </Menu.Item>
      {/* <Menu.Item style={{marginBottom: '4px'}}>
        <Text lineHeight='22px' fontSize='sm' color={'#F30618'}>Settings</Text>
      </Menu.Item> */}
      <Menu.Item style={{marginBottom: '4px'}} onClick={logout}>
        <Text lineHeight='22px' fontSize='sm' color={'#F30618'}>Logout</Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <Row  style={{height: '68px', position: position || 'fixed', width: '100%',borderBottom: pageState.questions ? '1px solid #E6E6E6' : 'inherit', zIndex: !position ? 100 : 'inherit', background:  !position ? 'white' : ''}}>
      <Col span={22} style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
        <div style={{paddingLeft: '24px', paddingBottom: '14px', display: 'flex', flexDirection: 'row', paddingTop: '14px'}}>
         { logoRequired && 
         <>
            <Image
                preview={false}
                src={logo}
                onClick={() => push('/dashboard')}
                style={{cursor: 'pointer'}}
            />
            {pageState.questions && 
              <div className={classNames('flex-row center ml-24')}>
                <Text lineHeight='28px' fontSize='lg' bold color={'#262626'}>{headerData.text}</Text>
                <Text lineHeight='22px' ml={'12px'} fontSize='sm' color={'#a6a6a6'}>{headerData.date}</Text>
              </div>}
          </>}
          
          {
          // Note: configure render prop to customize the other paths or other components 
          !logoRequired && 
            <div className={classNames('flex-row center')}>
              <Image src={leftArrow}  onClick={() => push('/')} preview={false} style={{cursor:'pointer', cursor: 'pointer', marginRight: '16px', width: '12px', height:'12px'}} />
              <Text lineHeight='28px'  fontSize='20px' bold color={'rgba(0, 0, 0, 0.85)'}>Profile</Text>
            </div>
          }
        </div>
      </Col>
      <Col span={2} style={{display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center'}}>
        <div style={{paddingRight: '24px', paddingBottom: '14px', paddingTop: '14px'}}>
          {pageState.home && <Dropdown size='large' overlay={menu} placement="bottomRight" >
              <div className="site-dropdown-context-menu" style={{display: 'flex' , alignItems: 'center'}}>
                <div key={1} style={{width: '40px', height: '40px', padding: '6px', height: '36px', borderRadius: '4px', border: '2px solid #b7b5b5', marginRight: '6px', display:'flex', justifyContent: 'center', alignItems: 'center', background: '#b7b5b5'}}>
                  <Image
                    preview={false}
                    src={user}
                    style={{width: '23px'}}
                  />
                </div>
                  <CaretDownOutlined width={'12px'}/>
              </div>
            </Dropdown>}
           {pageState.questions && <Button onClick={() => showConfirm(onOk)}>close</Button>}
          </div>
      </Col>
    </Row>
  )
}

export default Header