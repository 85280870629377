import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import {configureStore } from '@reduxjs/toolkit'
import authReducer  from '../reducers/authentication'
import pathsReducer from '../reducers/paths'

const loggerMiddleware = createLogger();

// export const store = createStore(
//     rootReducer,
//     applyMiddleware(
//         thunkMiddleware,
//         loggerMiddleware
//     )
// );

export const store = configureStore({
  reducer: rootReducer
})