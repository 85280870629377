import request  from '../utils/request'
import { GET_QUESTIONS, SUBMIT_QUESTIONNAIRE, VACCINATION_SUGGESTIONS, HEALTH_TEST_SUGGESTIONS, COVID_QUESTIONS,
  SUMBIT_COVID_QUESTIONS,GET_ALL_SUBMISSIONS } from '../routes/api'
import authHeader  from '../utils/authHeader'


const paths  = {
  default: {get: GET_QUESTIONS, sumbit: SUBMIT_QUESTIONNAIRE},
  covid: {get: COVID_QUESTIONS, sumbit: SUMBIT_COVID_QUESTIONS}
}
export default class QuestionnaireApi {
  static getQuestions = (options, onSuccess, onError) => {
    options['headers']= authHeader();
    return request({...options, url: paths[options.path]?.get || GET_QUESTIONS, method: 'post'}, onSuccess, onError)
  }

  static submitQestionnaire = (options, onSuccess, onError) => {
    options['headers']= authHeader()
    return request({...options, url: paths[options.path]['sumbit'] || SUBMIT_QUESTIONNAIRE, method: 'post'}, onSuccess, onError)
  }
  static getAllSubmits = (options, onSuccess, onError) => {
    options['headers']= authHeader();
    return request({...options, url: GET_ALL_SUBMISSIONS, method: 'post'}, onSuccess, onError)
  }
  static getVacSuggestions = (options, onSuccess, onError) => {
    options['headers']= authHeader()
    return request({...options, url: VACCINATION_SUGGESTIONS, method: 'get'}, onSuccess, onError)
  }

  static getHealthTestSuggestios = (options, onSuccess, onError) => {
    options['headers']= authHeader()
    return request({...options, url: HEALTH_TEST_SUGGESTIONS, method: 'get'}, onSuccess, onError)
  }

} 