import { Row , Col, Input, Alert, Checkbox} from 'antd'
import { useState } from 'react'
import { Typography, Button , Divider,Form} from 'antd';
import Text from '../atoms/Text'
import { useDispatch, useSelector } from 'react-redux';
import { authSelector, setTermsAccepted } from '../../reducers/authentication'


const Login = ({onLogin}) => {
  const [state, setState] = useState({email: '', password: ''})
  const auth = useSelector(authSelector)
  const dispatch = useDispatch()


  const acceptTerms = () => dispatch(setTermsAccepted())
  function LogIn(){
    onLogin(state.email.trim().toLowerCase(), state.password);
  }
  const validateMessages = {
    required: 'Field is required!',
    types: {
      email: "it is not a valid email!",
      //number: "${label} is not a valid number!",
      password:"Please Input your password!"
    }
  }
  // removing errors after certain time

  return (
    <>
        <div style={{display: 'flex', justifyContent:'center'}}>
          <Text bold  lineHeight='32px' fontSize='xl' color={'#262626'}>Log in</Text>
        </div>
        <Form
          name="login-form"
          onFinish={LogIn}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={["user", "email"]}
            rules={[
              {
                type: "email",
                required: true,
              },
            ]}
          >
        <div style={{marginTop: '18px'}}>
          <Text lineHeight='22px' fontSize='sm' color={'#262626'}>Email</Text>
          <Input style={{marginTop: '8px'}} value={state.email} onChange={(e) => setState({...state, email: e.currentTarget.value})} size="large" placeholder="Enter your email address"  />
        </div>
        </Form.Item>
        {/* <div style={{marginTop: '24px', marginBottom: '16px'}}> */}
        <Form.Item
            name= {["password"]}
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <div>
          <Text lineHeight='22px' fontSize='sm' color={'#262626'}>Password</Text>
          <Input.Password   value={state.password}  onChange={(e) => setState({...state, password: e.currentTarget.value})} style={{marginTop: '8px'}} placeholder='Enter password' size="large" />
        </div>
        </Form.Item>
        {/* </div> */}
        <div>
          <Checkbox checked={auth.termsAccepted} onChange={acceptTerms} />
          <Text lineHeight='24px' fontSize='sm' ml={'8px'} color={'rgba(0, 0, 0, 0.85)'}>{"I agree to Plan Heal's"}</Text>
          <Text lineHeight='24px' fontSize='sm' onClick={() => window.open(`${window.location.origin}/terms-of-service`, '_blank')} pointer bold ml={'4px'} mr={'4px'} color={'rgba(0, 0, 0, 0.45)'}>{'Terms of Service'}</Text>
          <Text lineHeight='24px' fontSize='sm' color={'rgba(0, 0, 0, 0.85)'}>{'and'}</Text>
          <Text lineHeight='24px' fontSize='sm' onClick={() => window.open(`${window.location.origin}/privacy-policy`, '_blank')} pointer bold ml={'4px'} color={'rgba(0, 0, 0, 0.45)'}>{'Privacy Policy'}</Text>
        </div>
         
        {auth.errors && <div style={{marginTop: '16px', textAlign: 'center'}}>
             <Alert message={auth.errors} type="error" />
          </div>
        }
        <div style={{marginTop: '16px'}}>
          <Button disabled={!auth.termsAccepted} loading={auth.loading}  htmlType="submit" type="primary" size='large' style={{backgroundColor: '#F30618', borderColor: 'inherit', borderRadius: '4px', height: '42px'}} block>
            <Text lineHeight='24px' fontSize='md' bold color={'white'}>Login</Text>
          </Button>
          <div style={{display: 'flex', justifyContent:'center'}}>
            <Text mt={'20px'} lineHeight='22px' fontSize='sm' color={'#F30618'}>Forgot Password</Text>
          </div>
        </div>
        </Form>
       
    </>
  )
}


export default Login