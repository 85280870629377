import React, { Suspense } from 'react';
import SuspenseLoader from '../components/molecules/SuspenseLoader'


const withLazyLoadingHOC = (Component) => (props) => {
  const lazyLoadFallback = () => <SuspenseLoader />

  return (
    <Suspense fallback={lazyLoadFallback()}>
      <Component {...props}/>
    </Suspense>
  )
}

export default withLazyLoadingHOC;