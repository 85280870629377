import { Switch, Route } from 'react-router-dom'
import { usePrivateRoutes }  from '../routes/private'
import Header from '../components/organisms/Header'
import Dashboard from './Dashboard'
import { Row, Col } from 'antd'
import { useSelector } from 'react-redux'


const Layout = () => {

  const state = useSelector(state =>  state.auth)
  console.log(state)

  //NOTE: replace string urls with from private routes.
  const showHeader  = !['/', '/login', '/register', '/signup', '/profile'].includes(window.location.pathname)

  return (
      <>
        {showHeader && <Header loggedIn={true}  />}
        <Row style={{display: 'flex'}}> 
          <Col span={24} style={{marginTop: showHeader ?  '100px' : 'inherit'}}>
            <Switch>
                {usePrivateRoutes()}
            </Switch>
          </Col>
        </Row>
      </>
    )
}

export default Layout