import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { renderIntoDocument, traverseTwoPhase } from 'react-dom/cjs/react-dom-test-utils.production.min';
import auth from './auth'
import { AuthApi  } from "../services";


export const initialState = {
  loading: false,
  spinLoading: false,
  isAuthenticated: false,
  authToken: null,
  errors: null,
  termsAccepted: false
}

export const login = createAsyncThunk('users/login', async ({username, password}, thunkAPI) => {
  try {
    const response = await AuthApi.login({data: {email: username, password}})
    return response.data
  }catch(e){
    // Promise.reject(e.response.data)
    // debugger
    return thunkAPI.rejectWithValue(e.response.data)
  }
}) 

export const loginAdmin = createAsyncThunk('admin/login', async ({username, password}, thunkAPI) => {
  try {
    const response = await AuthApi.loginAdmin({data: {email: username, password}})
    return response.data
  }catch(e){
    // Promise.reject(e.response.data)
    // debugger
    return thunkAPI.rejectWithValue(e.response.data)
  }
})


export const register = createAsyncThunk('users/register', async ({username, password}, thunkAPI) => {
  try {
    const response = await AuthApi.signup({data: {email: username, password}})
    return response.data
  }catch(e){
    // Promise.reject(e.response.data)
    // debugger
    return thunkAPI.rejectWithValue(e.response.data)
  }
})

export const gAuthLogin = createAsyncThunk('users/glogin', async ({token}, thunkAPI) => {
  try {
    const response = await AuthApi.GauthSignin({'token': JSON.stringify(token)})
    return response.data
  }catch(e){
    return thunkAPI.rejectWithValue(e.response.data)
  }
})

export const gAuthSignup = createAsyncThunk('users/gsignup', async ({token}, thunkAPI) => {
  try {
    const response = await AuthApi.GauthSignup({'token': JSON.stringify(token)})
    return response.data
  }catch(e){
    return thunkAPI.rejectWithValue(e.response.data)
  }
})


const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setTermsAccepted: (state, action) => {
      state.termsAccepted = !state.termsAccepted
    },
    setLoading: (state, action) => {
      state.loading = !state.loading
    },
    setSpiLoading: (state, action) => {
      state.spinLoading = !state.spinLoading
    }
  },
  extraReducers:  {
    [login.pending]: (state, action) => {
      state.loading = !state.loading
    },
    [login.fulfilled]: (state, action) => {
      state.authToken = action.payload.token
      state.isAuthenticated = true
      state.loading = !state.loading

    },
    [login.rejected]: (state, action) => {
      state.errors = action.payload.message
      state.isAuthenticated = false
      state.loading = !state.loading

    },
    [loginAdmin.pending]: (state, action) => {
      state.loading = !state.loading
    },
    [loginAdmin.fulfilled]: (state, action) => {
      state.authToken = action.payload.token
      state.isAuthenticated = true
      state.loading = !state.loading

    },
    [loginAdmin.rejected]: (state, action) => {
      state.errors = action.payload.message
      state.isAuthenticated = false
      state.loading = !state.loading

    },
    [register.pending]: (state, action) => {
      state.loading = !state.loading
    },
    [register.fulfilled]: (state, action) => {
      state.authToken = action.payload.token
      state.isAuthenticated = true
      state.loading = !state.loading
    },
    [register.rejected]: (state, action) => {
      state.errors = action.payload.message
      state.isAuthenticated = false
      state.loading = !state.loading
    },
    [gAuthLogin.pending]: (state, action) => {
      state.spinLoading = !state.spinLoading
    },
    [gAuthLogin.fulfilled]: (state, action) => {
      state.authToken = action.payload.token
      state.isAuthenticated = true
      state.spinLoading = !state.spinLoading
    },
    [gAuthLogin.rejected]: (state, action) => {
      state.errors = action.payload.message
      state.isAuthenticated = false
      state.spinLoading = !state.spinLoading
    },
    [gAuthSignup.pending]: (state, action) => {
      state.spinLoading = !state.spinLoading
    },
    [gAuthSignup.fulfilled]: (state, action) => {
      state.authToken = action.payload.token
      state.isAuthenticated = true
      state.spinLoading = !state.spinLoading
    },
    [gAuthSignup.rejected]: (state, action) => {
      state.errors = action.payload.message
      state.isAuthenticated = false
      state.spinLoading = !state.spinLoading
    }
  }
})

export const authSelector = (state) => state.authentication

export const { setTermsAccepted, setLoading, setSpiLoading } = authSlice.actions

const authReducer = authSlice.reducer

export default authReducer

