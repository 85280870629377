export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL =' REGISTER_FAIL'
export const LOADING = 'LOADING'


//helpers 

export const SET_LOADING = 'SET_LOADING'


//questions

export const SET_NEXT_DISABLED = 'SET_NEXT_DISABLED'
export const SET_ANSWERS_DATA = 'SET_ANSWERS_DATA'
export const SET_ERRORS = 'SET_ERRORS'
