import { Spin } from 'antd';



const SuspenseLoader = () => {
  return (
    <div className='loader' >
        <Spin />
    </div>
  )
}

export default SuspenseLoader;