import React, { lazy } from 'react';
// import withSuspenseLoaderHOC from '../utils/withSuspenseLoaderHOC';
import { Route, Redirect, withRouter } from 'react-router-dom';
import withErrorBoundary from '../utils/withErrorBoundary';
import { AnimatedRoute } from "react-router-transition";
import withLazyLoadingHOC from '../utils/withLazyLoadingHOC'
import SuspenseLoader from '../components/molecules/SuspenseLoader'
import { SwapLeftOutlined } from '@ant-design/icons';
import { useSelector, useStore,useDispatch } from 'react-redux';
import{setrequestToken} from '../reducers/paths';

const Dashboard = lazy(() => import('../pages/Dashboard'));
const HomePage = lazy(() => import('../pages/HomePage'));
const GetStarted = lazy(() => import('../pages/GetStarted'));


const QuestionsPage = lazy(() => import('../pages/HealthInquiriesPage'))
const SuccessPage = lazy(() => import('../pages/SuccessPage'))
const ProfilePage = lazy(() => import('../pages/ProfilePage'))


export const DASHBORD = '/dashboard';
export const HOME = '/';

export const QUESTIOTNS = '/questions'; 
export const SUCCESS = '/summary'; 


export const LOGIN = '/login'
export const SIGNUP = '/signup'
export const REPORTS = '/reports'
export const PROFILE = '/profile'
export const ADMIN_DASHBOARD='/admin/dashboard'






export const PRIVATE_ROUTES = [
    // {path: LECTURES, component: withLazyLoadingHOC(LecturesPage), exact: true},
    {path: HOME, component: withLazyLoadingHOC(HomePage), exact: true},
    {path: SIGNUP, component: withLazyLoadingHOC(GetStarted), exact: true},
    {path: REPORTS, component: withLazyLoadingHOC(HomePage), exact: true},
    {path: DASHBORD, component: withLazyLoadingHOC(Dashboard), exact: true},
    {path: QUESTIOTNS, component: withLazyLoadingHOC(QuestionsPage), exact: true},
    {path: SUCCESS, component: withLazyLoadingHOC(SuccessPage), exact: true},
    {path: PROFILE, component: withLazyLoadingHOC(ProfilePage), exact: true},
  ].map((route, index) => ({...route, key: index, component: withRouter(withErrorBoundary(route.component))}))


export const queryParams = (query) => {
    if(query[0] === '?') {
      query = query.slice(1)
    }
    return query.split('&').filter(Boolean).reduce((accumulator, element) => {
      const key = element.split('=')[0]
      const value = element.split('=').slice(1).join("=")
      return {...accumulator, [key]: value}
    }, {})
  }

export const usePrivateRoutes = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state.auth)
    const {qtoken}=queryParams(window.location.search);
    let isLoading  = false
    if(isLoading) {
      return (<SuspenseLoader/>)
    } else if (!localStorage.getItem('token')) {
      if(qtoken)
        return <Redirect to={{pathname:LOGIN, search:`?qtoken=${qtoken}`}} preserveQueyString />
      else
        return <Redirect to={{pathname:LOGIN}} />

    }
    if(qtoken){
      dispatch(setrequestToken({data: qtoken}))
    }
    return PRIVATE_ROUTES.map((route, index) => {

      if(route.animated) {
        return (
          <AnimatedRoute {...route}
            atEnter={{ offset: -100 }}
            atLeave={{ offset: -100 }}
            atActive={{ offset: 0 }}
            mapStyles={(styles) => ({
              transform: `translateX(${styles.offset}%)`,
            })}/>)
      } else {
        const {component: Component, ...rest} = route;
        return (<Route {...rest} render={(props) => {
          const { pageRefresh, uniqueId, identification_token }  = queryParams(window.location.search)
          if(pageRefresh === 'true') {
            return <Component {...props} key={identification_token || uniqueId} {...rest}/>
          } else {
            return <Component {...props} {...rest}/>
          }
        }} key={index} exact/>)
      }
    })
  }