import './App.css';
import { Switch, Route,Redirect } from 'react-router-dom';
import GetStarted from '../src/pages/GetStarted'
import Layout from '../src/pages/Layout';
import 'antd/dist/antd.css';
import { PRIVATE_ROUTES  } from '../src/routes/private'
import { useStore } from 'react-redux'
import TermsOfService from './pages/TermsOfService';
import Privacy from './pages/Privacy';
import AdminLoginAuth from '../src/pages/AdminLoginAuth';
//import AdminDashBoard from '../src/pages/AdminDashBoard';
import React,{lazy,withRouter} from 'react'
import withLazyLoadingHOC from './utils/withLazyLoadingHOC'
const AdminDashBoard = lazy(()=>import('../src/pages/AdminDashBoard'));

function App() {

  const store = useStore()

  return (
    <Switch>
      <div style={{height: '100vh', border: '3px', margin: 0, boxSizing: 'border-box'}}>
        <Route exact component={GetStarted} path={['/login']} />
        <Route exact component={TermsOfService} path={['/terms-of-service']} />
        <Route exact component={Privacy} path={['/privacy-policy']} />
        <Route exact component={AdminLoginAuth} path={['/admin/login']} />
        <Route exact render={() => <Redirect to='/admin/dashboard'/>} path={['/admin']} />
        <Route exact component={withLazyLoadingHOC(AdminDashBoard)} path={['/admin/dashboard']} />
        <Route exact path={PRIVATE_ROUTES.reduce((acc, { path }) => acc.concat(path), [])} component={Layout}/>{/* ets a navbar, bottom and placeholder for signed in users.*/}
      </div>
    </Switch>
  );
}

export default App;
